/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

.c-btn {
    display: inline-block;
    vertical-align: middle;
    padding: em(12px, 20px) em(60px, 20px) em(8px, 20px);
    border: 1px solid color('red');
    color: #fff;
    background: color('red');
    font-family: $font-special-elite;
    font-size: rem(20px);
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: timer('fast');

}

span.c-btn {
    background: color('body');
    border-color: darken(color('body'), 10%);
    color: darken(color('body'), 20%);
}

a.c-btn {
    cursor: pointer;

    @include link-states() {
        background: darken(color('red'), 10%);
        text-decoration: none;
    }

}


.c-btn--block {
    display: block;
    width: 100%;
}


.c-btn--small {
    font-size: rem(14px);
    padding: em(12px, 14px) em(20px, 14px) em(8px, 14px);
}


/**
 * Modifiers for the buttons
 */
.c-btn--stroke {
    background: transparent;
    color: color('red');
}

a.c-btn--stroke {

    @include link-states() {
        background: transparent;
        color: darken(color('red'), 10%);
        border-color: darken(color('red'), 10%);
    }

}
