///
/// Hide's content in a screen reader friendly way.
///
@mixin sr-only() {
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    position: absolute;
}
