/*------------------------------------*\
    #CUSTOM FONTS
\*------------------------------------*/

/**
 * List custom fonts
 */

// Example
// @font-face {
//     font-family: 'Klavika';
//     src: url('../fonts/Klavika-Regular.eot'); /* IE9 Compat Modes */
//     src: url('../fonts/Klavika-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('../fonts/Klavika-Regular.woff') format('woff'), /* Modern Browsers */
//          url('../fonts/Klavika-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
//          url('../fonts/Klavika-Regular.svg#1c0a77123c833724b533e6327487c008') format('svg'); /* Legacy iOS */
//     font-style:   normal;
//     font-weight:  400;
// }
