///
/// Return REM value
///
/// Support: http://caniuse.com/#search=rem
///
/// @param {pixel} $px - Target equivalent pixel value to output as rems.
///
/// @require {variable} $base-font-size
///
@function rem($px) {

    @if type-of($px) == 'number' and not unitless($px) {

        @return ($px / $base-font-size) * 1rem;

    } @else {

        @warn '`rem()` requires a number in `px` units.';
        @return null;

    }

}
