/*------------------------------------*\
    #INSET
\*------------------------------------*/

.o-inset {

    @include media('sm') {
        padding: 0 15%;
    }

}
