///
/// Return EM value
///
/// @param {pixel} $px - Target equivalent pixel value to output as ems.
/// @param {pixel} $base [$base-font-size] - Base pixel value to calculate against.
///
/// @require {variable} $base-config
///
@function em($px, $base: $base-font-size) {

    @if type-of($px) == 'number' and not unitless($px) {

        @return ($px / $base) * 1em;

    } @else {

        @warn '`em()` requires a number in `px` units.';
        @return null;

    }

}
