/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

.c-heading-page {
    color: #fff;
    font-size: rem(24px);
    font-weight: 300;
    line-height: em(46px, 36px);

    @include media('md') {
        font-size: rem(36px);
    }

    strong {
        font-weight: 400;
    }

}


.c-heading-section {
    color: #fff;
    font-family: $font-special-elite;
    font-size: rem(30px);
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;

    @include media('md') {
        font-size: rem(50px);
    }

}


.c-heading-title {
    font-size: rem(20px);
    font-weight: 300;
    line-height: em(36px, 26px);

    @include media('md') {
        font-size: rem(26px);
    }

    strong {
        color: #fff;
        font-weight: 400;
    }

}



.c-heading-subtitle {
    color: #fff;
    font-size: rem(20px);
    font-weight: 400;
    line-height: em(34px, 24px);

    @include media('md') {
        font-size: rem(24px);
    }

}


.c-heading-lead {
    font-size: rem(19px);

    @include media('md') {
        font-size: rem(22px);
    }

}

.c-heading-elite {
    font-family: $font-special-elite;
    font-size: rem(30px);
    font-weight: normal;
    line-height: 1.2;

    @include media('sm') {
        font-size: rem(50px);
    }

}
