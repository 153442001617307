/*------------------------------------*\
    #HEADER
\*------------------------------------*/

.c-header {
    padding: spacer(1) 0;
    margin-bottom: spacer(1);
}


.countdown {
    font-family: sans-serif;
    color: #fff;
    width: 260px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    text-align: center;
    font-size: 30px;

    @include media('sm') {
        width: 350px;
    }
}


.countdown > div{
    padding: 10px;
    width: 20%;
    background: fade-out(#000, 0.8);

}

.countdown div > span{
    border-radius: 3px;
    display: block;
    font-family: 'Special Elite', cursive;
    font-size: 30px;
    line-height: 36px;
    font-variant-numeric: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';

    @include media('sm') {
        font-size: 40px;
        line-height: 42px;
    }

}

.smalltext{
    color: #aaa;
    text-transform: uppercase;
    font-family: $base-font-family;
    text-align: center;
    font-size: 14px;

    @include media('sm') {
        font-size: 18px;
    }

}
