/*------------------------------------*\
    #LIST INLINE
\*------------------------------------*/

/**
 * List with direct children displaying inline.
 * [1] All items except the first have padding.
 */
.o-list-inline {
    @include list-unstyled();
    margin: spacer(1) 0;

    & > li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px;
    }

}
