/*------------------------------------*\
    #CARBONITE
\*------------------------------------*/

// scss-lint:disable all

/*above the dashboard*/
.carbonlite-tophtml {
    margin: 0;
    padding: 0;
}

/*above the dashboard*/
.carbonlite-dashboard {
    height: 83px;
    width: 260px;
    margin: 0 auto;
    padding: 10px 0;
    background: fade-out(#000, 0.8);

    @include media('sm') {
        width: 350px;
    }

}

.carbonlite-dash {
    display: flex;
    justify-content: center;
    width: 20%;
    height: 64px;
    margin: 0;
    float: left;
    padding-left: 0;
    padding-top: 0;
    position: relative;
    color: #FFF;
}

.carbonlite-tripdash {
    width: 62px;
    height: 44px;
    float: left;
    padding-left: 2px;
    padding-top: 10px;
    position: relative;
    color: #FFF;
    text-align: center;
}

.carbonlite-hours_dash, .carbonlite-minutes_dash, .carbonlite-seconds_dash  {
    background: transparent url('images/bg_dash.png') 0 0 no-repeat;
}

.carbonlite-days_dash  {
    background: transparent url('images/bg_dash.png') 0 0 no-repeat;

}

.carbonlite-days_trip_dash  {
    background: transparent url('images/bg_trip_dash.png') 0 0 no-repeat;
}

.carbonlite-weeks_dash  {
    background: transparent url('images/bg_dash.png') 0 0 no-repeat;
}

.carbonlite-weeks_trip_dash  {
    background: transparent url('images/bg_trip_dash.png') 0 0 no-repeat;
}


.carbonlite-digit {
    height: 36px;
    font-family: 'Special Elite', cursive;
    width: 18px;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    position: relative;
    float: none;

    @include media('sm') {
        height: 38px;
        width: 24px;
        flex: 0 0 24px;
        font-size: 40px;
        line-height: 42px;
    }

}

.carbonlite-dash .carbonlite-dash_title {
    flex: 0 0 100%;
    display: block;
    position: absolute;
    text-transform: uppercase;
    width: 100%;
    top: 40px;
    left: 0;
    color: #ccc;
    font-family: $base-font-family;
    text-align: center;
    font-size: 14px;

    @include media('sm') {
        font-size: 18px;
    }

}

.carbonlite-tripdash .carbonlite-dash_title {
    display: block;
    position: absolute;
    text-transform: uppercase;
    top: 33px;
    left: 17px;
    color: #AAA;
    text-align: center;
    font-size: 9px;
}

/*below the dashboard*/
.carbonlite-bothtml {
    margin: 0;
    padding: 0;
}
