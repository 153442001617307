/*------------------------------------*\
    #TEXT UTILITES
\*------------------------------------*/

/**
 * Text Alignment
 */
.u-text-center {
    text-align: center;
}

.u-text-right {
    text-align: right;
}

.u-text-caps {
    text-transform: uppercase;
}

.u-text-special {
    font-family: $font-special-elite;
    text-transform: uppercase;
    line-height: 1.2;
}
