/*------------------------------------*\
    #TYPOGRAPY
\*------------------------------------*/

/**
 * Headings
 *
 * In most cases you should avoid styling a specific heading type to have
 * custom distinct styles that would apply to all headings of that type.
 * Instead create a new component like c-heading or c-title. This may not
 * be the case when a CMS needs default headings to appear a specific way.
 */
@include headings() {
    margin: 0 0 spacer(1);
    font-size: 1.2rem;
    line-height: $base-line-height;
}




/**
 * Paragraphs
 */
p {
    margin: 0 0 spacer(1);
}





/**
 * Lists
 */
ul,
ol,
dl {
    margin: 0 0 spacer(1);
}





/**
 * Link Styles
 */
a {
    color: color('link');
    text-decoration: none;

    @include link-states() {
        text-decoration: underline;
        color: #fff;
    }

}



small {
    font-size: em(14px, 18px);
}





/**
 * Horizontal Rule
 */
hr {
    margin: spacer(0.75) auto;
    width: 100%;
    max-width: 1208px;
    height:   8px;
    border: 0;
    background: url('../img/hr-bg.png') no-repeat center;
    background-size: 100% auto;

    @include media('sm') {
        margin: spacer(1.5) auto;
    }

}
