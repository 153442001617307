////
/// VARIABLES
////


///
// Base global defaults
///
$font-special-elite: 'Special Elite', cursive;

$base-color:        #ccc;
$base-font-family:  'Oswald', sans-serif;
$base-font-size:    18px;
$base-line-height:  1.4;





/// Generic spacing unit
$spacing-unit:      20px;





///
// Colors
///
$color-config: (
    'body':         #ccc,
    'headings':     #00587d,
    'primary':      #337ab7,
    'red':          #eb221c,
    'link':         #d00000,
    'grey': (
        'default':  #7f7f7f,
        '25':       #bfbfbf,
        '75':       #474747
    )
);





///
// Timer Map for @function timer();
///
$timer-config: (
    'normal':   0.4s,
    'slow':    0.75s,
    'fast':    0.15s
);





///
// Z-index Map for @function z();
///
$z-index-config: (
    'example':   1000
);
