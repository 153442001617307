///
/// Retrieve color from sass map $color-config
///
/// @param {string} $color - Name of color to return from `$color-config`.
/// @param {string} $variant - Name of alternate version of a color to return
/// from `$color` map in `$color-config`.
///
/// @require {variable} $color-config
///
@function color($color, $variant: 'default') {

    @if map-has-key($color-config, $color) {

        $palette-config: map-get($color-config, $color);

        @if (type-of($palette-config) != 'map') {

            @return map-get($color-config, $color);

        } @else {

            @return map-get($palette-config, $variant);

        }

    } @else {

        @warn '`#{$color}` is not an available color or palette in `$color-config`';
        @return null;

    }

}
