/*------------------------------------*\
    #IMAGES
\*------------------------------------*/

/**
 * [1] remove spacing below inline images.
 */
img {
    vertical-align: middle; // [1]
    margin: 0 0 spacer(1);
}





/**
 * Figure
 */
figure {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 spacer(1);
}





figcaption {
    font-size: rem(10px);
    text-align: right;
}




svg {
    fill: currentColor;
}
