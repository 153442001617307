/*------------------------------------*\
    #SPACING UTILITIES
\*------------------------------------*/

.u-flush {
    margin-bottom: 0;
}

.u-close {
    margin-bottom: spacer(0.5);
}

.u-space {
    margin-bottom: spacer(1);
}
