/*------------------------------------*\
    #COLUMNS
\*------------------------------------*/

.o-columns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    @include media('sm') {
        flex-direction: row;
        margin: 0 spacer(-0.5);
    }

    @include media('md') {
        margin: 0 spacer(-1);
    }

}

.o-columns__panel {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;

    @include media('sm') {
        flex: 0 0 50%;
        padding: 0 spacer(0.5);
    }

    @include media('md') {
        padding: 0 spacer(1);
    }

}
