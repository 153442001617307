/*------------------------------------*\
    #COLOR UTILITES
\*------------------------------------*/

// Generate color classes for each color in $color-utilites
// Thid implementation doesn't support the color variations.
$color-utilities-config: (
    'red'
);


/**
 * Color utilities to force a specific color.
 */
@each $color in $color-utilities-config {

    .u-color-#{$color} {
        color: color($color);
    }

}
