/*------------------------------------*\
    #IMAGE UTILITIES
\*------------------------------------*/

/**
 * Image's max width conforms to the parent's width.
 */
.u-img-respond {
    max-width: 100%;
    height: auto;
}





/**
 * Image matches it's containers width
 */
.u-img-full {
    width: 100%;
    height: auto;
}
