/*------------------------------------*\
    #SCHEDULE
\*------------------------------------*/

.c-schedule {
    padding-top: 8px;
    background: url('../img/hr-bg.png') no-repeat center top;
    background-size: 100% auto;
    width: 100%;
    text-align: left;

    @include media('sm') {
        font-size: rem(20px);
    }

}

.c-schedule__item {
    width: 100%;
    padding-bottom: 8px;
    background: url('../img/hr-bg.png') no-repeat center bottom;
    background-size: 100% auto;

    @include media('sm') {
        display: flex;
        align-items: center;
        margin: 0 spacer(-0.25);
    }

}

.c-schedule__item-block {
    display: block;
    padding: spacer(0.25);

    @include media('sm') {
        padding: spacer(0.5) spacer(0.25);
    }

}

.c-schedule__item-block--date {
    flex: 0 0 15%;
    font-size: em(24px, 20px);
}

.c-schedule__item-block--city {
    flex: 0 0 20%;
}

.c-schedule__item-block--location {
    flex-grow: 1;
}

.c-schedule__item-block--cta {
    flex: 0 0 160px;
}
