/*------------------------------------*\
    #ISLAND
\*------------------------------------*/

/**
 * Add a nice gap below a block of content.
 */
.o-island {
    margin-bottom: spacer(1);

    @include media('sm') {
        margin-bottom: spacer(2.5);
    }

}
