/*------------------------------------*\
    #PANELS
\*------------------------------------*/

.c-panel {
    width: 100%;
    padding: spacer(1);
    background: linear-gradient(0deg, fade-out(#000, 0.7) 0%, fade-out(#000, 1) 70%),
                url('../img/panel-bg.jpg') center;
    box-shadow: 3px 3px 23px 8px fade-out(#000, 0.5);

    @include media('sm') {
        padding: spacer(1);
    }

}

/**
 * Flex column wrapper fixes IE10 min-hieght flex support
 * https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
 */
.c-panel__cta-wrap {

    @include media('sm') {
        display: flex;
        flex-direction: column;
    }

}

.c-panel__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: spacer(1) spacer(-0.5);
    padding: 10px spacer(0.5);
    background: #f00 url('../img/panel-cta-bg.jpg') center;

    @include media('sm') {
        min-height: 80px;
        margin: spacer(1) spacer(-1);
        padding: 5px spacer(1);
    }

}

