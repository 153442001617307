/*------------------------------------*\
    #ASPECT
\*------------------------------------*/

// Config to easily generate modifiers for different image aspect ratios
$aspect-config: (
    '2x1': 50%,
    '16x9': 9 / 16 * 100%
);


/**
 * Enforce an aspect ratio on a responsive image before the page loads. Uses
 * the same trick as responsive videos.
 *
 * [1] Hide any access overflow of the image in case it doesn't quite fit.
 * [2] Padding 100% creates a square by default.
 */
.o-aspect {
    overflow: hidden; // [1]
    margin-bottom: spacer(1);
    padding-top: 100%; // [2]
    width: 100%;
    position: relative;

    & > img,
    & > iframe {
        margin: 0;
        width:  100%;
        height: 100%;
        position: absolute;
        top:  0;
        left: 0;
    }

}


/**
 * Loop throught the config and build out aditional sizes.
 */
@each $prop, $value in $aspect-config {

    .o-aspect--#{$prop} {
        padding-top: $value;
    }

}
