/*------------------------------------*\
    #PAGE
\*------------------------------------*/

/**
 * Body
 */
html {
    background: #000 url('../img/body-bg.jpg') center;
    color: $base-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: 300;
    line-height: $base-line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    min-height: 100vh;
    text-align: center;
}
